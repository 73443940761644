<template>
  <template v-if="!loading && coursesData.length">
    <div class="liff-my-courses-page">
      <div class="page__header">
        <div class="header-title">{{ DateUtil.sayhi() }}，{{ userData.info.name }}</div>
      </div>

      <div class="page__wrapper">
        <div class="ac__header" align="center">
          <!-- <div class="header-title">嗨 {{ userData.info.name }}，您參與的班級為：</div> -->

          <a-select
            v-model:value="courseTypeValue"
            :options="courseTypeOptions"
            size="large"
            :style="{ width: '200px' }"
          />
        </div>
        
        <div class="ac__body letwecare-courses-wrapper">
          <a-row :gutter="24">
            <a-col
              v-for="item in filteredData"
              :lg="6" :md="12" :xs="24"
            >
              <div class="course-card" @click="toCoursePage(item.id)">
                <a-row>
                  <a-col :span="24">
                    <div class="card-title ellipsis-1">{{ item.name }}</div>

                    <div class="card-info">
                      <div>
                        <template v-if="item.coaches.length">
                          教練：{{ item.coaches.map((elem) => elem.name ).join(",") }}
                        </template>
                        <template v-else>
                          尚未排定教練
                        </template>
                      </div>

                      <div>
                        每週{{ item.days.map(day => DateUtil.toChineseDay(day)).join('、') }} {{ item.start_time }} ~ {{ item.end_time }}
                      </div>
                    </div>

                    <template v-if="item.coaches.length > 0">
                      <a-avatar
                        class="card-avatar"
                        :src="item.coaches[0].avatar!=null ? item.coaches[0].avatar.uri : require('@/assets/icons/avatar.png')"
                        size="large"
                        :style="{ position: 'absolute', right: '4px', bottom: '18px' }"
                      />
                    </template>
                  </a-col>
                </a-row>
                
                <div class="card-stat">
                  <a-row>
                    <a-col :span="8" align="center">
                      <AppstoreOutlined class="mr-1" /> {{ item.counter.lession }}
                    </a-col>
                    <a-col :span="8" align="center">
                      <PictureOutlined class="mr-1" /> {{ item.counter.image }}
                    </a-col>
                    <a-col :span="8" align="center">
                      <TeamOutlined class="mr-1" /> {{ item.counter.participant }}
                    </a-col>
                  </a-row>
                </div>

                <div class="card-progress">
                  <a-progress :percent="item.completed" :show-info="false" />
                </div>

                <div class="card-meta">
                  <a-row>
                    <a-col :span="12">
                      完成度 <span :style="{ color: 'black' }">{{ item.completed }}%</span>
                    </a-col>
                    <a-col :span="12" align="right" v-if="item.completed < 100 && item.lessions.length > 0">
                      上課日 <span :style="{ color: 'black' }">{{ moment(item.upcomingDatetime).format('MM/DD') }}</span>
                    </a-col>
                  </a-row>
                </div>
              </div>
            </a-col>
          </a-row>



          <!-- <a-row>
            <a-col
              v-for="course in filteredData"
              :span="24"
            >
              <div class="course-card" @click="toCoursePage(course.id)">
                <div class="card-header">
                  <div class="header-title">{{ course.name }}</div>
                  <div class="header-subtitle">每週{{ course.days.map(day => DateUtil.toChineseDay(day)).join('、') }} {{ course.start_time }} ~ {{ course.end_time }}</div>
                  <div class="header-subtitle" :style="{ color: '#FF8A00' }">
                    {{ course.classroom }}
                  </div>
                </div>

                <div class="card-actions">
                  <a-button
                    class="action-btn"
                    type="primary"
                  >前往班級平台</a-button>
                </div>
              </div>
            </a-col>
          </a-row> -->
        </div>
      </div>
    </div>
  </template>

  <template v-if="!loading && filteredData.length === 0">
    <div class="liff-my-courses-page">
      <div :style="{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '80vh' }">
        <div :style="{ textAlign: 'center', fontSize: '1.45rem', marginBottom: '28px' }">您還沒有加入任何班級 😆</div>
        <a-button :style="{ margin: '0 auto', fontSize: '1rem' }" @click="closeLiff">點我關閉頁面</a-button>
      </div>
    </div>
  </template>

  <template v-if="loading">
    <div :style="{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '70vh' }">
      <a-spin
        tip="載入中"
        :indicator="indicator"
      />
    </div>
  </template>
</template>

<script>
import { defineComponent, ref, onMounted, h, computed } from 'vue'
import { LoadingOutlined, FileTextOutlined, ScheduleOutlined, AppstoreOutlined, PictureOutlined, TeamOutlined } from '@ant-design/icons-vue'
import moment from 'moment'
import api from '@/apis'
import DateUtil from '@/utils/DateUtil'
import liff from '@line/liff/dist/lib'

export default defineComponent({
  components: {
    FileTextOutlined,
    ScheduleOutlined,
    AppstoreOutlined,
    PictureOutlined,
    TeamOutlined
  },
  setup() {
    /* Loading */
    const loading = ref(true);

    /* Liff */
    const context = ref({});
    const initLiff = (async () => {
      try {
        await liff.init({ liffId: process.env.VUE_APP_LIFF_MY_COURSES });
        context.value = liff.getContext();
        console.log(context.value);
      } catch (err) {
        console.log(err.code, err.message);
      }
    });

    const closeLiff = () => {
      liff.closeWindow();
    }

    /* Data */
    const userData = ref({});
    const coursesData = ref([]);
    const filteredData = computed(() => {
      let data = [];

      if (courseTypeValue.value == 'all') data = coursesData.value
      else if (courseTypeValue.value == 'completed') {
        coursesData.value.forEach(item => {
          if (item.completed == 100) data.push(item);
        });
      }
      else if (courseTypeValue.value == 'active') {
        coursesData.value.forEach(item => {
          if (item.completed >= 0 && item.completed < 100) data.push(item);
        });
      }

      return data
    });

    /* Course Filter */
    const courseTypeValue = ref('active');
    const courseTypeOptions = ref([{
      label: '上課中',
      value: 'active'
    }, {
      label: '已完課',
      value: 'completed'
    }, {
      label: '全部班級',
      value: 'all'
    }]);

    /* Api */
    const getUserDataByLineId = (async () => {
      let line_id;
      if (process.env.VUE_APP_ENV == 'production') {
        line_id = context.value.userId;
      } else if (process.env.VUE_APP_ENV == 'development') {
        line_id = process.env.VUE_APP_DEV_LINE_ID;
      }

      const response = await api.v1.line.parserLineId({
        line_id: line_id
      });
      userData.value = response.data.result;
      console.log(userData.value)
    });

    const getUserCoursesData = (async () => {
      try {
        const response = await api.v1.course.belongList(userData.value.uid, {
          role: userData.value.role
        });

        // 統計數據 & 計算完成度 & 下一個上課日
        response.data.result.forEach(course => {
          // 統計數據
          let counter = {
            lession: course.lessions.length,
            image: 0,
            participant: course.users.length
          }

          let completeLessionCount = 0;
          let nextLession = {};
          course.lessions.forEach(lession => {
            counter.image += lession.images.length

            if (moment().isAfter(`${lession.start_date} ${course.end_time}`)) {
              completeLessionCount += 1;
            }

            if (Object.keys(nextLession).length == 0) {
              if (moment().isBefore(`${lession.start_date} ${course.end_time}`)) {
                nextLession = lession;
              }
            }
          });
          course.counter = counter;

          // 計算完成度
          course.completed = 0;
          if (course.lessions.length > 0) {
            course.completed = Math.round(completeLessionCount / course.lessions.length * 100);
          }
          
          // 下一個上課日
          course.upcomingDatetime = null;
          if (course.lessions.length > 0 && course.upcomingDatetime == null) {
            course.upcomingDatetime = `${nextLession.start_date} ${nextLession.start_time}`;
          }
        });

        coursesData.value = response.data.result;
        console.log(coursesData.value);
      } catch (err) {
        console.log(err);
      }
    });

    /* Mounted */
    onMounted(async () => {
      loading.value = true;

      await initLiff();
      await getUserDataByLineId();
      await getUserCoursesData();
      

      loading.value = false;
    });

    /* Loading Style */
    const indicator = h(LoadingOutlined, {
      style: {
        fontSize: '2rem',
        marginBottom: '20px'
      },
      spin: true,
    });

    return {
      moment,

      /* Liff */
      closeLiff,

      /* Loading */
      loading,

      /* Data */
      userData,
      coursesData,
      filteredData,

      /* Course Filter */
      courseTypeValue,
      courseTypeOptions,

      /* Loading Style */
      indicator,

      /* Utils */
      DateUtil
    }
  },
  methods: {
    /* Router */
    toCoursePage(id) {
      this.$router.push({
        name: 'LiffCourseList',
        params: {
          course_id: id
        }
      });
    }
  }
})
</script>

<style lang="scss">
.liff-my-courses-page {
  background: #FFF3E0;
  min-height: 100vh;

  .page__header {
    height: 50px;
    background: white;

    .header-title {
      font-size: 1.15rem;
      font-weight: 450;
      line-height: 50px;
      text-align: center;
      padding: 0 12px;
    }
  }

  .page__wrapper {
    padding: 20px 20px 40px 20px;
  }

  .ac__header {
    margin-bottom: 24px;

    .header-title {
      font-size: 1.5rem;
      font-weight: 400;
      text-align: center;
      font-size: 1.4rem;
      margin-bottom: 8px;
    }
  }

  .ac__body {
    padding: 0 12px;

    .course-card {
      background: white;
      border-radius: 4px;
      padding: 25px;
      margin-bottom: 20px;

      .card-header {
        margin-bottom: 12px;

        .header-title {
          color: #212121;
          font-weight: 500;
          font-size: 1.45rem;
        }

        .header-subtitle {
          color: #424242;
          font-size: 1.2rem;
        }
      }

      .card-img {
        max-height: 160px;
      }

      .card-actions {
        padding: 12px 0;
        
        .action-btn {
          width: 100%;
          font-size: 1.2rem;
          height: 48px;
        }
      }
    }
  }
}
</style>